<template>

  <v-card v-if="item">
    <v-card-title class="accent white--text" >
      eNPS Score
      <v-spacer />
      <span class="text-body-2" v-if="item.total_count > $store.getters.reportSettings.min_results">{{ $tc('interface.participant_count') }}: {{ item.total_count }}</span>
    </v-card-title>
    <v-card-text>
      <p class="text-body-1 pt-4">{{ item.question.question }}</p>
      <v-flex class="text-center font-weight-light" v-if="item.total_count >= $store.getters.reportSettings.min_results">
        <table align="center">
          <tr>
            <th v-if="compares.length > 0 || Object.keys(item.benchmarks).length > 0" style="width: 150px"></th>
            <th class="nps detractor text-h5" colspan="6">Criticaster</th>
            <th class="nps passive text-h5" colspan="2">Passief</th>
            <th class="nps promotor text-h5" colspan="2">Promotor</th>
            <th class="text-h5">NPS-score</th>
          </tr>
          <tr>
            <th v-if="compares.length > 0 || Object.keys(item.benchmarks).length > 0">All participants</th>
            <th class="">{{ this.values[1].toFixed(1) | dutch_number }}%</th>
            <th class="">{{ this.values[2].toFixed(1) | dutch_number }}%</th>
            <th class="">{{ this.values[3].toFixed(1) | dutch_number }}%</th>
            <th class="">{{ this.values[4].toFixed(1) | dutch_number }}%</th>
            <th class="">{{ this.values[5].toFixed(1) | dutch_number }}%</th>
            <th class="">{{ this.values[6].toFixed(1) | dutch_number }}%</th>
            <th class="">{{ this.values[7].toFixed(1) | dutch_number }}%</th>
            <th class="">{{ this.values[8].toFixed(1) | dutch_number }}%</th>
            <th class="">{{ this.values[9].toFixed(1) | dutch_number }}%</th>
            <th class="">{{ this.values[10].toFixed(1) | dutch_number }}%</th>
            <th v-if="compares.length > 0 || $store.state.currentBenchmarks.length > 0" class="score text-center display-1 font-weight-light">
              {{ this.nps.toFixed(0) | dutch_number}}
            </th>
            <th v-else class="score text-center display-3 font-weight-light">
              {{ this.nps.toFixed(0) | dutch_number}}
            </th>
          </tr>

          <tr v-for="(compare, compareIndex) in compares" :key="'compare_' + compareIndex">
            <th>{{ compare.name }}</th>
            <template v-if="compares[compareIndex].nps !== compares[compareIndex].nps || compares[compareIndex].total_count >= $store.getters.reportSettings.min_results">
              <th colspan="10">
                <hr>
              </th>
              <th colspan="1">
                <response-too-low-tooltip />
              </th>
            </template>
            
            <template v-else-if="compare.values">
              <th class="">{{ compare.values[1].toFixed(1) | dutch_number }}%</th>
              <th class="">{{ compare.values[2].toFixed(1) | dutch_number }}%</th>
              <th class="">{{ compare.values[3].toFixed(1) | dutch_number }}%</th>
              <th class="">{{ compare.values[4].toFixed(1) | dutch_number }}%</th>
              <th class="">{{ compare.values[5].toFixed(1) | dutch_number }}%</th>
              <th class="">{{ compare.values[6].toFixed(1) | dutch_number }}%</th>
              <th class="">{{ compare.values[7].toFixed(1) | dutch_number }}%</th>
              <th class="">{{ compare.values[8].toFixed(1) | dutch_number }}%</th>
              <th class="">{{ compare.values[9].toFixed(1) | dutch_number }}%</th>
              <th class="">{{ compare.values[10].toFixed(1) | dutch_number }}%</th>
              <th class="score text-center display-1 font-weight-light">
                {{ compare.nps.toFixed(0) | dutch_number}}
              </th>
            </template>
          </tr>

          <tr v-for="(benchmark, benchmarkIndex) in $store.state.currentBenchmarks" :key="'benchmark_' + benchmarkIndex"> 
            <th>
              <span v-if="benchmark.type == 'benchmark_project'">Project: {{ benchmark.name }}</span>
              <span v-else>Benchmark: {{ benchmark.name }}</span>
            </th>
            {{ benchmark.nps }}
            <template v-if="benchmarks[benchmark.id] && benchmarks[benchmark.id].nps && benchmark.type !== 'benchmark_project'">
              <th colspan="10">
                <hr>
              </th>
              <th class="score text-center display-1 font-weight-light">
                {{ benchmarks[benchmark.id].nps.toFixed(0) | dutch_number}}
              </th>
            </template>
            <template v-else-if="benchmarks[benchmark.id] && benchmarks[benchmark.id].values && benchmarks[benchmark.id].nps && benchmark.type === 'benchmark_project'">
              <th class="">{{ benchmarks[benchmark.id].values[1].toFixed(1) | dutch_number }}%</th>
              <th class="">{{ benchmarks[benchmark.id].values[2].toFixed(1) | dutch_number }}%</th>
              <th class="">{{ benchmarks[benchmark.id].values[3].toFixed(1) | dutch_number }}%</th>
              <th class="">{{ benchmarks[benchmark.id].values[4].toFixed(1) | dutch_number }}%</th>
              <th class="">{{ benchmarks[benchmark.id].values[5].toFixed(1) | dutch_number }}%</th>
              <th class="">{{ benchmarks[benchmark.id].values[6].toFixed(1) | dutch_number }}%</th>
              <th class="">{{ benchmarks[benchmark.id].values[7].toFixed(1) | dutch_number }}%</th>
              <th class="">{{ benchmarks[benchmark.id].values[8].toFixed(1) | dutch_number }}%</th>
              <th class="">{{ benchmarks[benchmark.id].values[9].toFixed(1) | dutch_number }}%</th>
              <th class="">{{ benchmarks[benchmark.id].values[10].toFixed(1) | dutch_number }}%</th>
              <th class="score text-center display-1 font-weight-light">
                {{ benchmarks[benchmark.id].nps.toFixed(0) | dutch_number}}
              </th>
            </template>
          </tr>

          <tr>
            <th v-if="compares.length > 0  || Object.keys(item.benchmarks).length > 0"></th>
            <th class="nps detractor">1</th>
            <th class="nps detractor">2</th>
            <th class="nps detractor">3</th>
            <th class="nps detractor">4</th>
            <th class="nps detractor">5</th>
            <th class="nps detractor">6</th>
            <th class="nps passive">7</th>
            <th class="nps passive">8</th>
            <th class="nps promotor">9</th>
            <th class="nps promotor">10</th>
            <th>&nbsp;</th>
      </tr>
        </table>
    
      </v-flex>
      <v-flex v-else>
        <v-alert :value="true" type="warning">
          {{ $t('interface.onvoldoende_respons') }}
        </v-alert>
      </v-flex>
    </v-card-text>
  </v-card>

</template>

<script>
  import { NolostInsights } from '@/mixins/NolostInsights'
  import ResponseTooLowTooltip from './ResponseTooLowTooltip.vue'

  export default {
    name: "NpsScore",
    mixins: [NolostInsights],
    props: ['content'],
    components: {
      ResponseTooLowTooltip
    },
    data () {
      return {
        values: false,
        loading: true,
        nps: false,
        item: false,
        benchmarks: {},
        compares: [],
      }
    },
    computed: {
      currentBenchmarksCount () { return this.$store.state.currentBenchmarks },
      currentComparesCount () { return this.$store.state.currentCompares },
      currentFiltersCount () { return this.$store.state.currentFilters },
    },
    methods: {
      getData () {
        this.loading = true;

        this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' + this.content.question)
          .then(resp => {
            this.item = resp.data[0] // Store question
            this.values = new Array(10).fill(0) // Create a list with 0's

            // Assign the counts 
            this.item.counts.map((count) => {
              this.values[count.value_as_int] = count.count / this.item.total_count * 100
            })

            // Get the NPS scores
            this.nps = this.getNps()

            this.refreshCompares()
            this.getBenchmarkNps()
            this.loading = false;
          })
          .catch(err => {
            this.error = err
            this.loading = false;
          })
      },
      getNps () {
        let detractors = this.values[1] + this.values[2] + this.values[3] + this.values[4] + this.values[5] + this.values[6]
        let promoters = this.values[9] + this.values[10]
        return promoters - detractors
      },
      getBenchmarkNps() {
        // console.log()
        this.$store.state.currentBenchmarks.forEach((benchmark) => {
          // if (this.item.benchmarks[benchmark.id])
          if (benchmark.type === 'benchmark_project') {
            
            let benchmarkProject = this.item.benchmarks[benchmark.id]
            benchmarkProject.values = new Array(10).fill(0)
            benchmarkProject.counts.forEach((count) => {
              benchmarkProject.values[count.value_as_int] = count.count / benchmarkProject.total_count * 100
            })            
            let detractors = benchmarkProject.values[1] + benchmarkProject.values[2] + benchmarkProject.values[3] + benchmarkProject.values[4] + benchmarkProject.values[5] + benchmarkProject.values[6]
            let promoters = benchmarkProject.values[9] + benchmarkProject.values[10]
            this.item.benchmarks[benchmark.id].nps = promoters - detractors
            this.benchmarks[benchmark.id] = this.item.benchmarks[benchmark.id]
          } else {
            // let benchmarkRegular = this.item.benchmarks[benchmark.id]
            this.item.benchmarks[benchmark.id].nps = (this.item.benchmarks[benchmark.id].perc_pos - this.item.benchmarks[benchmark.id].perc_neg) * 100
            this.benchmarks[benchmark.id] = this.item.benchmarks[benchmark.id]
          }
        })
        // console.log(this.benchmarks)
      },
      updateCompares(currentCompares, previousCompares) { 
        if (currentCompares && previousCompares && (currentCompares.length > previousCompares.length)) {
          this.addCompare(currentCompares.slice(-1)[0], currentCompares.length - 1)
        } else if (currentCompares.length === 0) {
          this.compares = []
        } else if ((currentCompares && previousCompares && (currentCompares.length < previousCompares.length)) ) {
          let removedCompareIndex = previousCompares.findIndex(f => !currentCompares.includes(f))
          this.removeCompare(removedCompareIndex)
        } 
      },
      refreshCompares() {
        this.$store.state.currentCompares.forEach((compare, compareIndex) => {
          this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' + this.content.question + '&compare=' + this.$store.state.availableCompares.findIndex(c => compare.key === c.key && compare.value === c.value))
          .then(resp => { 
            this.compares.splice(compareIndex, 1, resp.data[0])
            this.compares[compareIndex].name = compare.name

            // Create a list with 0's
            this.compares[compareIndex].values = new Array(10).fill(0)

            // Assign the counts 
            this.compares[compareIndex].counts.map((count) => {
              this.compares[compareIndex].values[count.value_as_int] = count.count / this.compares[compareIndex].total_count * 100
            })
            this.compares[compareIndex].nps = this.getNpsCompare(compareIndex)
          })
          .catch(err => {
            this.error = err
            this.loading = false;
          })
        })
      },
      addCompare(compare, compareIndex) {
        if (compare && compare.key && compare.value) {
          // And get the data related to this compare
          this.axios.get(process.env.VUE_APP_API_URL + '/insights/questions/?question_ids=' + this.content.question + '&compare=' + this.$store.state.availableCompares.findIndex(c => compare.key === c.key && compare.value === c.value))
          .then(resp => { 
            this.compares.splice(compareIndex, 1, resp.data[0])
            this.compares[compareIndex].name = compare.name
            // Create a list with 0's
            this.compares[compareIndex].values = new Array(10).fill(0)

            // Assign the counts 
            this.compares[compareIndex].counts.map((count) => {
              this.compares[compareIndex].values[count.value_as_int] = count.count / this.compares[compareIndex].total_count * 100
            })
            this.compares[compareIndex].nps = this.getNpsCompare(compareIndex)
          })
          .catch(err => {
            this.error = err
            this.loading = false;
          })
        }
      },
      removeCompare(compareIndex) {
        this.compares.splice(compareIndex, 1)
      },
      getNpsCompare(compareIndex) {
        let detractors = this.compares[compareIndex].values[1] + this.compares[compareIndex].values[2] + this.compares[compareIndex].values[3] + this.compares[compareIndex].values[4] + this.compares[compareIndex].values[5] + this.compares[compareIndex].values[6]
        let promoters = this.compares[compareIndex].values[9] + this.compares[compareIndex].values[10]
        return promoters - detractors
      },
      updateBenchmarks() {
        this.getData()
      },
    },
    mounted() {
      this.getData()
    },
    watch: {
      'currentFiltersCount': 'getData',
      'currentComparesCount': 'updateCompares',
      'currentBenchmarksCount': 'updateBenchmarks',
    },
  }
</script>

<style scoped>
  .nps {
    width: 80px;
    height: 40px;
    align: center;
  }
  .promotor {
    background-color: #00ac85;
  }
  .passive {
    background-color: #f1a135;
  }
  .detractor {
    background-color: #e4003a;
  }
  .score {
    width: 120px;
    align: center;
  }
</style>
